import { dew as _clientDew } from "./lib/client";
import { dew as _clusterDew } from "./lib/cluster";
import { dew as _luaScriptDew } from "./lib/lua-script";
import { dew as _errorsDew } from "./lib/errors";
import { dew as _genericTransformersDew } from "./lib/commands/generic-transformers";
import { dew as _FLUSHALLDew } from "./lib/commands/FLUSHALL";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = {
        enumerable: true,
        get: function () {
          return m[k];
        }
      };
    }
    Object.defineProperty(o, k2, desc);
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });
  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RedisFlushModes = exports.GeoReplyWith = exports.defineScript = exports.createCluster = exports.commandOptions = exports.createClient = void 0;
  const client_1 = _clientDew();
  const cluster_1 = _clusterDew();
  exports.createClient = client_1.default.create;
  exports.commandOptions = client_1.default.commandOptions;
  exports.createCluster = cluster_1.default.create;
  var lua_script_1 = _luaScriptDew();
  Object.defineProperty(exports, "defineScript", {
    enumerable: true,
    get: function () {
      return lua_script_1.defineScript;
    }
  });
  __exportStar(_errorsDew(), exports);
  var generic_transformers_1 = _genericTransformersDew();
  Object.defineProperty(exports, "GeoReplyWith", {
    enumerable: true,
    get: function () {
      return generic_transformers_1.GeoReplyWith;
    }
  });
  var FLUSHALL_1 = _FLUSHALLDew();
  Object.defineProperty(exports, "RedisFlushModes", {
    enumerable: true,
    get: function () {
      return FLUSHALL_1.RedisFlushModes;
    }
  });
  return exports;
}